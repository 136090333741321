*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    
    --bg: #cfcfcf;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.loader-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #242f3f;
}

.loadingbar {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: #32d6ff;
}



.loader {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 48%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 4px solid rgb(255, 13, 235);
    background-color: black;
    box-shadow: 0px 0px 20px 8px #ff76ff;
    transition: all 0.5s;
    animation: loader 2s infinite ease;
}

.loader-inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: rgb(31, 210, 255);
    animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(180deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader-inner {
    0% {
        height: 0%;
    }

    25% {
        height: 0%;
    }

    50% {
        height: 100%;
    }

    75% {
        height: 100%;
    }

    100% {
        height: 0%;
    }
}

.player {
    top: 80%;
    left: 85%;
    width: 50px;
    height: 50px;
    background: linear-gradient(135deg, #14ffe9, #ffeb3b, #ff00e0);
    display: grid;
    grid-template-columns: 48px 1fr 48px;
    grid-gap: 16px;
    padding: 6px 12px;
    position: absolute;
    z-index: 1;
    border-radius: 10px;
    transform: scale(0.001,0.001);
    translate: transform 0.3;

    
    box-shadow: 0px 0px 20px 8px #ff76ff;
    animation: animate 1.5s linear infinite;

}



.player .control,
.player span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
}

.player .control {
    background: #1b1b1b;
    height: 55px;
    width: 65px;
    z-index: 999;
    border-radius: 6px;
    text-align: center;
}


.control,#current {
    display: flex;
    justify-content: center;
    align-items: center;
}

.player #playbtn {
    font-size: 24px;
    color: #fff;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: inherit;
    cursor: pointer;
}

#playbtn:hover {
    -webkit-text-fill-color: rgb(8, 8, 8);
}

@keyframes animate {
    100%{
        filter: hue-rotate(360deg);
    }
}

.player.zealous {
    transform: scale(1,1);
}

.resume {
    position: absolute;
    left: 10%;
    top: 10%;
    transform: translate(-10%, -10%);
    margin: auto;
    color:rgb(255, 255, 255);
    transform: scale(0.001,0.001);
    translate: transform 0.3;
}

.resume button{
    position: absolute;
    left: 38%;
    top: 40%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding:12px;
    color:rgb(255, 255, 255);
    font-size:24px;
    outline:none;
    background-color: rgba(122, 122, 122, 0.183);
    border-radius: 10px;
    cursor:pointer;
    border:2px solid rgba(255, 255, 255, 0.062);
    box-shadow:0px 0px 20px 8px #ffffff00;
}



.resume button:hover{
    font-size:24px;
    padding:12px;
    border-radius: 10px;
    color:#98fff6;
    background-color: rgba(0, 0, 0, 0.26);
    box-shadow: 0px 0px 20px 4px #84f7ffa2;
    border: 2px solid #9bf8ff;
    transition: all 0.5s;
}

img {
    margin:auto;
    border-radius: 2%;
    opacity: 0.85;
    left:50%;
    right:50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 70%;
    
    background: linear-gradient(135deg, #14ffe9, #ffeb3b, #ff00e0);
    box-shadow: 0px 0px 20px 4px #9cf8ffa2;
    border: 2px solid #a9f9ff;
    transition: all 0.5s;
    animation: animate 1.5s linear infinite;
}

.resume2 .resumebox .image1 {
    margin:auto;
    border-radius: 2%;
    opacity: 0.85;
    left:50%;
    right:50%;
    transform: translate(-50%, -50%);
    width: 110%;
    height: 110%;
    
    background: linear-gradient(135deg, #14ffe9, #ffeb3b, #ff00e0);
    box-shadow: 0px 0px 20px 4px #9cf8ffa2;
    border: 2px solid #a9f9ff;
    transition: all 0.5s;
    animation: animate 1.5s linear infinite;
}

.resume.showcase {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    transform: scale(1,1);
    translate: transform 0.7;
    transition: all 0.5s;
}

.resume2 {
    position: absolute;
    left: 10%;
    top: 10%;
    transform: translate(-10%, -10%);
    margin: auto;
    color:rgb(255, 255, 255);
    transform: scale(0.001,0.001);
    translate: transform 0.3;
}

.resume2 button{
    position: absolute;
    left: 38%;
    top: 40%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding:12px;
    color:rgb(255, 255, 255);
    font-size:24px;
    outline:none;
    background-color: rgba(122, 122, 122, 0.183);
    border-radius: 10px;
    cursor:pointer;
    border:2px solid rgba(255, 255, 255, 0.062);
    box-shadow:0px 0px 20px 8px #ffffff00;
}



.resume2 button:hover{
    font-size:24px;
    padding:12px;
    border-radius: 10px;
    color:#98fff6;
    background-color: rgba(0, 0, 0, 0.26);
    box-shadow: 0px 0px 20px 4px #84f7ffa2;
    border: 2px solid #9bf8ff;
    transition: all 0.5s;
}

.resume2.showcase {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    transform: scale(1,1);
    translate: transform 0.7;
    transition: all 0.5s;
}

.point {
    position: absolute;
    top: 85%;
    left: 31%;
    /*pointer-events: none; */
}

    .point .label {
        position: absolute;
        top: -20px;
        left: -20px;
        width: 800px;
        height: 70px;
        border-radius: 6px;
        color: #ffffff;
        font-family: Helvetica, Arial, sans-serif;
        text-align: center;
        line-height: 40px;
        font-weight: 100;
        font-size: 16px;
        cursor: help;
        transform: scale(0.001, 0.001);
        transition: transform 0.3s;
    }

   


    .copy-text input.text{
        padding: 5px;
        font-size: 24px;
        color: rgb(255, 255, 255);
        border-radius:10px;
        width: 700px;
        border: none;
        outline: none;
        background: #00000077;
        border: 1px solid #ffffff77;
    }

    .copy-text button{
        padding:10px;
        color:rgb(255, 255, 255);
        font-size:24px;
        outline:none;
        background-color: rgba(122, 122, 122, 0.183);
        border-radius: 20px;
        cursor:pointer;
        border:2px solid rgba(255,255,255,0);
        box-shadow:0px 0px 20px 8px #ffffff00;
    }

    .copy-text button:hover{
        font-size:24px;
        padding:10px;
        border-radius: 20px;
        color:#00ffea;
        background-color: black;
        box-shadow: 0px 0px 20px 4px #00eeffa2;
        border: 2px solid #00eeff;
        transition: all 0.5s;
    }

    .copy-text button:active {
        background: #ffffff;
        color: #00ff88;
    }

    .copy-text button:before{
        content:"Copied";
        position:absolute;
        top:-45px;
        right: 10px;
        background:#3380c98a;
        color: white;
        padding:8px 8px;
        border-radius:20px;
        font-size:15px;
        display:none;
    }

    .copy-text button:after{
        content:"";
        position:absolute;
        top:-20px;
        right:25px;
        width:10px;
        height:5px;
        display:flex;
    }

    .copy-text.active button:before,
    .copy-text.active button:after{
        display:block;
    }


    .point.visible .label {
        transform: scale(1, 1);
    }


    
